<template>
  <div>
    <nav class="navbar sticky-top navbar-expand-lg navbar-light ">
      <div class="container-fluid">
        <div class="brands">
          <router-link to="/">
            <img src="./assets/images/marathe-logo-removebg-preview.png" class="logo" />
            <a href="#" class="navbar-brand"></a>
          </router-link>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link to="/">
                <a class="nav-link" aria-current="page" href="#">Home</a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/aboutus">
                <a class="nav-link" href="#">About Us</a>
              </router-link>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-bs-auto-close="outside" href="#" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                Projects
              </a>
              <ul class="dropdown-menu">
                <li class="dropend"><a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" href="#">Marathe Enterprises</a>
                  <ul class="dropdown-menu">
                    <li><router-link to="/shreeshubhanand"><a href="" class="dropdown-item">Shree Shubhanand-NX</a></router-link></li>
                    <li><router-link to="/greenland"><a href="" class="dropdown-item">Greenland-NX</a></router-link></li>
                    
                </ul>  
                </li>
                <li class="dropend"><a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" href="#">Impero Buildcon</a>
                  <ul class="dropdown-menu">
                    <li><router-link to="/satyadeep"><a href="" class="dropdown-item">Satyadeep-NX</a></router-link></li>
                  </ul>
                </li>
                <li class="dropend"><a class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" href="#">VPM Developers</a>
                  <ul class="dropdown-menu">
                    <li><router-link to="/mahalaxmiashish"><a href="" class="dropdown-item">Mahalaxmi Ashish-NX</a></router-link></li>
                  </ul>
                </li>
                <li><router-link to="/underconstruction"><a class="dropdown-item" href="#">Under Construction Projects</a></router-link></li>
                <li><router-link to="/upcomingprojects"><a class="dropdown-item" href="#">Upcoming Projects</a></router-link></li>
                
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/services">
                <a class="nav-link" href="#">Services</a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/contactus">
                <a class="nav-link" href="#">Contact Us</a>
              </router-link>
            </li>
          </ul>
          <ul class="me-auto navbar-nav contacts">
            <li class="nav-item me-auto">
              <a href="tel:8080146766">
                <v-icon>mdi-phone</v-icon>
                +91 8080146766
              </a>
            </li>

            <li class="nav-item">
              <a href="mailto:contact@gurunetwork.in">
                <v-icon>mdi-email</v-icon>
                care@marathegroup.com
              </a>
            </li>

          </ul>

        </div>
      </div>
    </nav>

  </div>
</template>

<script>

</script>

<style scoped>
.logo {
  height: 5rem;
  width: auto;
  margin-top: 0px;
  margin-bottom: -10px;
}

button .navbar-toggler {
  color: black !important;
}

.navbar a {
  text-decoration: none !important;
  color: #bd9a68 !important;
}

.navbar a:hover {
  color: black !important;
}

.navbar{
  font-family: "Lato", sans-serif !important;
}

@media(max-width:1023px) {
  .logo {
    height: 60px;
    width: auto;
  }

  .navbar-brand {
    position: relative;
    top: 10px;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .contacts {
    display: none;
  }
}

@media(min-width:1025px) {
  .contacts {
    font-size: 1.1rem;
    color: #bd9a68;

  }

  .navbar-nav {
    padding-left: 50px;
  }

  .logo {
    padding-left: 50px;
  }

  .navbar-brand {
    position: relative;
    top: 5px;
    padding-left: 10px;
    font-size: 1.7rem;
    padding-top: 20px
  }

  .navbar {
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 5% !important;
    margin-right: 0% !important;
  }

  .navbar li {
    margin-left: 10px;
    margin-right: 10px;
  }


  .navbar .container-fluid {
    padding-left: 5vw;
  }
}
</style>
