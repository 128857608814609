<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>Upcoming Projects</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Upcoming Projects</li>
                </ol>
            </div>
            <!-- end container -->
        </header>

        <section class="facilities">
            <div class="container">
                <div class="row">
                    
                    <div class="justify-content-center">
                        <figure>
                            
                            <figcaption>
                                <h3 class="text-center">VIGHNHAR NX</h3>
                                <h5 class="text-center">SHIVGANGA NAGAR, AMBARNATH</h5>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style></style>

<script>

</script>