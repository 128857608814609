<template>
    <div class="">
        <section class="footer-bar" data-aos="fade-up">
            <div class="container">
                <div class="inner wow fadeIn text-center">
                    <div class=" d-flex justify-content-around">
                        <div class="wow fadeInUp col-6" data-wow-delay="0.05s">
                            <v-icon style="font-size: 3rem;" class="mb-5">mdi-map-marker</v-icon>
                            <h3>Address Info</h3>
                            <p>Office No. 08, Plot No. 47,<br>
                                Nirankar, Opp. HDFC Bank,<br>
                                Hutatma Chowk, Ambarnath East.
                                421 501.</p>
                        </div>
                        <!-- end col-4 -->
                        <div class="wow fadeInUp" data-wow-delay="0.10s">
                            <v-icon style="font-size: 3rem;" class="mb-5">mdi-clock</v-icon>
                            <h3>Working Hours</h3>
                            <p>Monday to Friday <strong>10:00</strong> to <strong>18:00</strong> <br>
                                Saturday <strong>Closed</strong>
                                <br> Sunday <strong>10:00</strong> to <strong>20:00</strong>
                            </p>

                        </div>
                        <!-- end col-4 -->

                        <!-- end col-4 -->
                    </div>
                    <!-- end row -->
                </div>
                <!-- end inner -->
            </div>
            <!-- end container -->
        </section>
        <!-- end footer-bar -->
        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.05s"> <img
                            src="./assets/images/marathe-logo.jpg" alt="Image" class="logo">
                        <p>By aiming to take the life quality to an upper level with the whole realized Projects,
                            Marathe Group continues
                            to be the address of comfort.</p>

                        <!-- end select-box -->
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-2 col-md-6 wow fadeInUp" data-wow-delay="0.10s">
                        <ul class="footer-menu">
                            <li style="color: #fff;font-size: 1.2rem;">QUICK LINKS</li>
                            <li><router-link to="/"><a href="#">Home</a></router-link></li>
                            <li><router-link to="/aboutus"><a href="#">About Us</a></router-link></li>
                            <li><router-link to="/services"><a href="#">Services</a></router-link></li>
                            <li><router-link to="/contactus"><a href="#">Contact Us</a></router-link></li>
                        </ul>
                    </div>
                    <!-- end col-2 -->
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.15s">
                        <ul class="footer-menu">

                            <li style="color: #fff;font-size: 1.2rem;">PROJECTS</li>
                            <li style="color: #fff;">Marathe Enterprises
                                <ul>
                                    <li><router-link to="/shreeshubhanand"><a href="">Shree Shubhanand-NX</a></router-link></li>
                                    <li><router-link to="/greenland"><a href="">Greenland-NX</a></router-link></li>
                                </ul>   
                            </li>
                            <li style="color: #fff;">Impero Buildcon
                                <ul>
                                    <li><router-link to="/satyadeep"><a href="">Satyadeep-NX</a></router-link></li>
                                </ul>   
                            </li>
                            <li style="color: #fff;">VPM Developers
                                <ul>
                                    <li><router-link to="/mahalaxmiashish"><a href="">Mahalaxmi Ashish-NX</a></router-link></li>
                                </ul>   
                            </li>
                            <li><router-link to="/underconstruction"><a href="">Under Construction Projects</a></router-link></li>
                            <li><router-link to="/upcomingprojects"><a href="">Upcoming Projects</a></router-link></li>
                        </ul>
                    </div>
                    <!-- end col-2 -->
                    <div class="col-lg-2 wow fadeInUp" data-wow-delay="0.20s">
                        <div class="contact-box">
                            <h5>CONTACT US</h5>
                            <h4><a href="tel:8080146766" style="color: inherit;">+91 8080146766</a></h4>
                            <p><a href="mailto:contact@gurunetwork.in" style="color: inherit;">care@marathegroup.com</a>
                            </p>
                            <ul>
                                <li><a href="https://www.facebook.com/Marathegroups?mibextid=ZbWKwL"><v-icon>mdi-facebook</v-icon></a></li>
                                <li><a href="https://www.instagram.com/marathe.group/?igshid=MzRlODBiNWFlZA%3D%3D"><v-icon>mdi-instagram</v-icon></a></li>
                                <li><a href="#"><v-icon>mdi-linkedin</v-icon></a></li>
                                <li><a href="#"><v-icon>mdi-youtube</v-icon></a></li>
                            </ul>
                        </div>
                        <!-- end contact-box -->
                    </div>
                    <!-- end col-4 -->
                    <div class="col-12 text-center"> <span class="">© 2024 Marathe Group</span>
                        
                    </div>

                    
                    <!-- end col-12 -->
                </div>
                <!-- end row -->
                <hr style="background-color: #3c3e41;">
                <p class="text-center">
                        Designed and developed by Abhishek Wani | Rushikesh Wani
                </p>
            </div>
            <!-- end container -->
        </footer>
    </div>
</template>

<script>

</script>

<style></style>