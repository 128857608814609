<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>About Us</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
            </div>
            <!-- end container -->
        </header>
        <section class="about-content" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2><span>2 Decades</span> of Leadership in Real Estate Sector</h2>
                        <h5>By aiming to take the life quality to an upper level with the whole realized Projects of
                            comfort.</h5>
                        <p style="font-size: 1.1rem;">
                            An empire in not build in a day. Two decades ago, Late Mr. Kishor Tulshiram Marathe started
                            this enterprise with his first project "Shree Ganesh Krupa" located at Ulhasnagar in the
                            year 1988. He was passionate about his work and has completed various different projects in
                            Ambernath, Ulhasnagar, and Badlapur. Not only did he had a strong work ethic but was also a
                            kind person at heart who intended to help people.
                            <br>

                            His empire, his enterprise was not abandoned after him but is now growing more and more. Mr.
                            Vihar Kishor Marathe and Mr. Vishal Kishor Marathe, his sons are thriving while walking down
                            his path on this journey. Just like with new flowers comes new seeds, new generation comes
                            with new goals and notions.
                            <br>
                            Now, Marathe Group is not only focusing on building dream homes for people but also making
                            it worth the experience. Their projects are focusing on the environmental sustainability as
                            well as residential safety.
                            <br>

                            Home automation i.e control every switch in your house via phone, smart locks technology for
                            main doors, and sensor lights in lobby with the motive of electric sustainability are the
                            new concepts they are bringing into this business which are adding value to people and
                            nature which will be reflected in many of their upcoming projects as more innovative notions
                            will be implemented.
                            <br>

                            Now, Marathe Group is not only focusing on building dream homes for people but also making
                            it worth the experience. Their projects are focusing on the environmental sustainability as
                            well as residential safety.
                            <br>
                            By creating noteworthy contributions, Marathe Group has increased present expectations for
                            quality and residential excellence. What’s more, we constantly seek approaches to improve
                            our services and solutions even.
                            <br>
                            Here, we take a gigantic measure of pride in an occupation well done, and our customers’
                            objective accomplished. Contact us! We are delighted to help you out.
                            <br>
                            New Gen, New Journey, Same Virtues.
                        </p>
                    </div>
                    <!-- end col-12 -->

                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </section>
    </div>
</template>

<script>

</script>

<style></style>