<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>Greenland-NX</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item" aria-current="page"><a href="#">Marathe Builders</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Greenland-NX</li>
                </ol>
            </div>
            <!-- end container -->
        </header>
        <div class="gap"></div>
        <div class="container">
            <div class=" row">
                <div class="col-md-7">
                    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="../assets/images/shubhanand1.jpg" class="d-block w-100" alt="image">
                            </div>
                            <div class="carousel-item">
                                <img src="../assets/images/shubhanand2.jpg" class="d-block w-100" alt="image">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-5" style="font-size: 1.2rem;">
                    Welcome to Marathe Enterprises's Greenland NX - a luxurious yet humble space situated in the heart of the city . It has been conceived and designed with the purpose of creating a lifestyle that has classic yet grand. Brilliantly designed 7 storey Building with optimum space & parking utilization Colonnade elevation with functional & sustainable design with security and CCTV surveillance . Designed by expert Architects & consultants . Guranteed Impeccable quality of construction with the on - site work subject to stringent quality standards .
                </div>

            </div>
            <div class="">
                
        </div>
        <h1 class="text-center plan" style="font-family: 'Playfair Display', serif;">PLAN</h1>
                <v-card>
                    <v-tabs v-model="tab" align-tabs="center" color="#bd9a68">
                        <v-tab :value="1">1 BHK</v-tab>
                        <v-tab :value="2">2 BHK</v-tab>
                        <v-tab :value="3">Floor Plan</v-tab>
                        <v-tab :value="4">Amenities</v-tab>
                        <v-tab :value="5">Location Map</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item :value="1">
                            <v-container fluid>
                                <v-img :src="require('../assets/images/shubhanand1bhk.jpg')"></v-img>
                            </v-container>
                        </v-window-item>
                    </v-window>
                    <v-window v-model="tab">
                        <v-window-item :value="2">
                            <v-container fluid>
                                <v-img :src="require('../assets/images/shubhanand2bhk.jpg')"></v-img>
                            </v-container>
                        </v-window-item>
                    </v-window>
                    <v-window v-model="tab">
                        <v-window-item :value="3">
                            <v-container fluid>
                                <v-img :src="require('../assets/images/shubhanandfloorplan.jpg')"></v-img>
                            </v-container>
                        </v-window-item>
                    </v-window>
                    <v-window v-model="tab">
                        <v-window-item :value="4">
                            <v-container fluid>
                                <v-img :src="require('../assets/images/shubhanandamenities.jpg')"></v-img>
                            </v-container>
                        </v-window-item>
                    </v-window>
                    <v-window v-model="tab">
                        <v-window-item :value="5">
                            <v-container fluid>
                                <v-img :src="require('../assets/images/shubhanandmap.jpg')"></v-img>
                            </v-container>
                        </v-window-item>
                    </v-window>
                </v-card>

            </div>

            <div class="mt-8 text-center desktop">
                <h1 class="text-center plan mb-8" style="font-family: 'Playfair Display', serif;">LOCATION</h1>
                
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15071.379098283438!2d73.176786!3d19.201981!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7953b1e1d0ddf%3A0x8aa5a593f2628e74!2sShree%20Shubhanand%20NX!5e0!3m2!1sen!2sin!4v1713453195983!5m2!1sen!2sin" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>

                
              </div>

              <div class="mt-8 text-center mobile">
                <h1 class="text-center plan mb-8" style="font-family: 'Playfair Display', serif;">LOCATION</h1>
                
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15071.379098283438!2d73.176786!3d19.201981!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7953b1e1d0ddf%3A0x8aa5a593f2628e74!2sShree%20Shubhanand%20NX!5e0!3m2!1sen!2sin!4v1713453195983!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
              </div>
        <div class="gap">

        </div>
    </div>
</template>

<style>

.plan {
    font-size: 3rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 5rem;
}
</style>

<script>
 export default {
    data: () => ({
      tab: null,
    }),
  }
</script>