<template>
  <div>
    <!-- //Carousel -->
    <section class="carousel">
      <swiper :space-between="30" :centeredSlides="true" @swiper="onSwiper" :effect="'fade'" :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }" :pagination="{
        clickable: true,
      }" :navigation="true" :modules="modules" class="mySwiper">
        <swiper-slide><img src="../assets/images/mahalaxmi-ashish-nx.png" alt=""></swiper-slide>
        <swiper-slide><img src="../assets//images/greenland.jpg" alt=""></swiper-slide>
        <swiper-slide><img src="../assets//images/greenland2.jpg" alt=""></swiper-slide>
      </swiper>
    </section>


    <!-- //Counter -->
    <!-- <section class="counter gap">
      <div class="row">
        <div class="angry-grid col-md-3">
          <div class="item-0 justify-content-end">
            <v-icon>mdi-check-outline</v-icon>
          </div>
          <div class="item-1">
            <h2 class="timer count-title count-number">
              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='18' :duration='1' prefix='' suffix=''
                separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' />
            </h2>
          </div>
          <div class="item-2">
            <p class="count-text ">Completed<br>Projects</p>
          </div>
        </div>

        <div class="angry-grid col-md-3">
          <div class="item-0">
            <v-icon>mdi-account-hard-hat</v-icon>
          </div>
          <div class="item-1">
            <h2 class="timer count-title count-number">
              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='3' :duration='1.5' prefix='' suffix=''
                separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' />
            </h2>
          </div>
          <div class="item-2">
            <p class="count-text ">Ongoing<br>Construction</p>
          </div>
        </div>

        <div class="angry-grid col-md-3">
          <div class="item-0">
            <v-icon>mdi-domain-plus</v-icon>
          </div>
          <div class="item-1">
            <h2 class="timer count-title count-number">
              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='6' :duration='2' prefix='' suffix=''
                separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' />
            </h2>
          </div>
          <div class="item-2">
            <p class="count-text ">Upcoming<br>Projects</p>
          </div>
        </div>

        <div class="angry-grid col-md-3">
          <div class="item-0">
            <v-icon>mdi-handshake</v-icon>
          </div>
          <div class="item-1">
            <h2 class="timer count-title count-number">
              <vue3-autocounter ref='counter' :startAmount='0' :endAmount='3' :duration='2.5' prefix='' suffix=''
                separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' />
            </h2>
          </div>
          <div class="item-2">
            <p class="count-text ">Joint<br>Ventures</p>
          </div>
        </div>

      </div>
    </section> -->
  
    <section class="benefits " data-aos="fade-right">
      <div class="container">
        <div class="row">

          <!-- end col-12 -->
          <div class="col wow fadeInUp" data-wow-delay="0s">
            <figure> <v-icon>mdi-check-outline</v-icon> <b></b> </figure>
            <h5>COMPLETED PROJECTS</h5>
            <span class="odometer"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='18' :duration='1'
                prefix='' suffix='' separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' /></span>
          </div>
          <!-- end col -->
          <div class="col wow fadeInUp" data-wow-delay="0.05s">
            <figure><v-icon>mdi-account-hard-hat</v-icon><b></b> </figure>
            <h5>ONGOING CONSTRUCTION </h5>
            <span class="odometer"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='3' :duration='1.5'
                prefix='' suffix='' separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' /></span>
          </div>
          <!-- end col -->
          <div class="col wow fadeInUp" data-wow-delay="0.10s">
            <figure> <v-icon>mdi-domain-plus</v-icon> <b></b> </figure>
            <h5>UPCOMING PROJECTS</h5>
            <span class="odometer"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='6' :duration='2'
                prefix='' suffix='' separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' /></span>
          </div>
          <!-- end col -->
          <div class="col wow fadeInUp" data-wow-delay="0.15s">
            <figure> <v-icon>mdi-handshake</v-icon> <b></b> </figure>
            <h5>JOINT VENTURES COMPLETED</h5>
            <span class="odometer"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='3' :duration='2.5'
                prefix='' suffix='' separator=',' decimalSeparator='.' :decimals='0' :autoinit='true' /></span>
          </div>
          <!-- end col -->

          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>


    <!-- //About Us -->
    <section class="about-us text-center gap desktop " data-aos="fade-left">
      <h2 class="mb-8">ABOUT <span style="color: var(--primary-color);">MARATHE GROUP</span></h2>

      <p>Marathe Group, founded by Late Mr. Kishor Tulshiram Marathe in 1988 with "Shree Ganesh Krupa" in Ulhasnagar,
        has flourished under the leadership of his sons, Mr. Vihar Kishor Marathe and Mr. Vishal Kishor Marathe. Their
        commitment to quality and innovation has expanded the group's focus from building dream homes to integrating
        environmental sustainability and residential safety. With features like home automation, smart locks, and sensor
        lights, Marathe Group is not just creating homes but enhancing experiences while prioritizing eco-friendly
        practices. Their dedication to excellence and continuous improvement sets new standards in the industry,
        ensuring customer satisfaction and a promising future for their projects.
      </p>

    </section>

    <section class="about-us text-center gap mobile " data-aos="fade-right">
      <h2 class="mb-8">ABOUT <span style="color: var(--primary-color);">MARATHE GROUP</span></h2>

      <p>Marathe Group, founded by Late Mr. Kishor Tulshiram Marathe in 1988 with "Shree Ganesh Krupa" in Ulhasnagar,
        has flourished under the leadership of his sons, Mr. Vihar Kishor Marathe and Mr. Vishal Kishor Marathe.
        <span id="dots">...</span>

        <span id="more">
          Their
          commitment to quality and innovation has expanded the group's focus from building dream homes to integrating
          environmental sustainability and residential safety. With features like home automation, smart locks, and
          sensor
          lights, Marathe Group is not just creating homes but enhancing experiences while prioritizing eco-friendly
          practices. Their dedication to excellence and continuous improvement sets new standards in the industry,
          ensuring customer satisfaction and a promising future for their projects.
        </span>
      </p>
      <v-btn color="#bd9a68" id="myBtn" @click="myFunction">Read More</v-btn>
    </section>

    <!-- //Projects -->
    <section class="projects text-center gap" data-aos="fade-up">
      <h2 style="color: var(--primary-color)" class="mb-8">PROJECTS</h2>


      <h3 style="color: var(--primary-color)">MARATHE ENTERPRISES</h3>
      <div class="d-flex justify-content-around">
        <div class="">
          <router-link to="/shreeshubhanand">
          <div class="glassBox">
            <div class="glassBox__imgBox">
              <img src="../assets/images/greenland.jpg" alt="">
              <h3 class="glassBox__title">Shree Shubhanand - NX</h3>
            </div>
          </div>
        </router-link>
        </div>
        <div class="">
          <router-link to="/greenland">
          <div class="glassBox">
            <div class="glassBox__imgBox">
              <img src="../assets/images/greenland2.jpg" alt="">
              <h3 class="glassBox__title">Greenland - NX</h3>
            </div>

          </div>
        </router-link>
        </div>
      </div>
      <div style="padding-top: 5%;"></div>
      <h3 style="color: var(--primary-color)">IMPERO BUILDCON</h3>
      <div style="padding-top: 1.5%;"></div>
      <div class="d-flex justify-content-center">
        <div class="">
          <router-link to="/satyadeep">
          <div class="glassBox">
            <div class="glassBox__imgBox">
              <img src="../assets/images/satyadeep2.png" alt="">
              <h3 class="glassBox__title">Satyadeep - NX</h3>
            </div>
          </div>
        </router-link>
        </div>
      </div>

      <div style="padding-top: 5%;"></div>
      <h3 style="color: var(--primary-color)">VPM DEVELOPERS</h3>
      <div style="padding-top: 1.5%;"></div>
      <div class="d-flex justify-content-center">
        <div class="">
          <router-link to="/mahalaxmiashish">
          <div class="glassBox">
            <div class="glassBox__imgBox">
              <img src="../assets/images/mahalaxmi-ashish-nx2.png" alt="">
              <h3 class="glassBox__title">Mahalaxmi Ashish - NX</h3>
            </div>
          </div>
        </router-link>
        </div>
      </div>
    </section>

    <section class="property-customization" data-aos="fade-up">
    
      <!-- end video-bg -->
      <div class="container">
        <div class="row">
          <div class="col-12 wow fadeInUp">
            <h2 class="text-center">OUR <span style="color: var(--primary-color);">SERVICES</span> </h2>
            
          </div>
          <!-- end col-12 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"> <v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-door</v-icon>
              <figcaption>Doors</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.05s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"><v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-wall</v-icon>
              <figcaption>Walls</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.10s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"> <v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-lightbulb</v-icon>
              <figcaption>Electrification</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.15s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"> 
              <v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-window-open</v-icon>
              <figcaption>Windows</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.20s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"><v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-table-chair</v-icon>
              <figcaption>Kitchen</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.25s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"><v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-lock</v-icon>
              <figcaption>Security</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"><v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-toilet</v-icon>
              <figcaption>Toilets</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.05s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"> <v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-shower</v-icon>
              <figcaption>Bathrooms</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.10s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"><v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-floor-plan</v-icon>
              <figcaption>Flooring</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
          <div class="col-lg-2 col-md-4 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.15s">
            <figure data-toggle="tooltip" data-placement="top"
              title="The smaller male cones release pollen, which fertilizes"><v-icon color="" style="font-size: 2.5rem;color: var(--primary-color);">mdi-wrench</v-icon>
              <figcaption>Plumbing</figcaption>
            </figure>
          </div>
          <!-- end col-2 -->
         
          <!-- end col-2 -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </section>
    <div class="gap">

    </div>
    <!-- end property-customization -->
    <!-- <section class="certificates">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-12 wow fadeInUp"> <b>09</b>
            <h4><span>Property</span> Certificates</h4>
            <small>Smaller male cones </small>
          </div>
      
          <div class="col-lg-2 col-md-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.05s">
            <figure> <a href="images/certificate01.jpg" data-fancybox><img src="../assets/images/certificate01.jpg"
                  alt="Image"></a> </figure>
          </div>

          <div class="col-lg-2 col-md-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.10s">
            <figure> <a href="images/certificate02.jpg" data-fancybox><img src="../assets/images/certificate02.jpg"
                  alt="Image"></a> </figure>
          </div>

          <div class="col-lg-2 col-md-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.15s">
            <figure> <a href="images/certificate03.jpg" data-fancybox><img src="../assets/images/certificate03.jpg"
                  alt="Image"></a> </figure>
          </div>
  
          <div class="col-lg-2 col-md-3 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.20s">
            <figure> <a href="images/certificate04.jpg" data-fancybox><img src="../assets/images/certificate04.jpg"
                  alt="Image"></a> </figure>
          </div>
 
        </div>
 
      </div>
 
    </section> -->
    <!-- end certificates -->
   
  </div>

</template>

<style>
.desktop {
  display: block;
}

.mobile {
  display: none;
}

#more {
  display: none;
}


.gap {
  margin-top: 7.5%;
  margin-bottom: 7.5%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {

  width: 100%;
  height: 90vh;

}

.angry-grid {
  display: grid;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  gap: 0px;
  height: 100%;

}

.counter {
  margin-left: 5%;
  margin-right: 10%;
  margin-top: 5%;
  background-color: #fdf5e2;
  position: relative;
  border-radius: 10px;

}

.item-0 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 3rem;

  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 3;
  grid-column-end: 1;

}

.item-1 {

  display: flex;
  justify-content: center;
  align-items: end;
  font-size: 3rem;
  grid-row-start: 1;
  grid-column-start: 2;

  grid-row-end: 2;
  grid-column-end: 3;

}

.item-2 {


  text-align: center;
  font-size: 1.5rem;
  grid-row-start: 2;
  grid-column-start: 2;

  grid-row-end: 3;
  grid-column-end: 3;

}

.count-number {
  font-weight: 800;
  font-size: 2.5rem;
}

.benefits figure{
  font-size: 2rem;
}

.about-us {
  margin-left: 25%;
  margin-right: 25%;
}

.about-us p {
  font-size: 1.25rem;
}

.glassBox {
  width: 100%;
  height: 300px;
  max-width: 400px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.glassBox,
.glassBox * {
  box-sizing: border-box;
  transition: 400ms;
}

.glassBox__imgBox img {
  display: block;
  width: 100%;
  height: auto;
}

.glassBox__title {
  text-align: center;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 400;
}

.glassBox:hover .glassBox__imgBox {
  transform: translateY(-50px);
}

.glassBox:hover .glassBox__imgBox img {
  transform: translate(0px, -40px) rotate(0) scale(1.2);
}

/* //Mobile View Only//  */
@media(max-width: 1023px) {
  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .about-us p {
    font-size: 1.15rem;

  }

  .gap {
    margin-top: 15%;
    margin-bottom: 15%;
  }

  .swiper-slide img {
    width: 100%;
    height: auto;
  }



  .counter {
    margin-left: -15%;
  }

  .about-us {
    margin-left: 10%;
    margin-right: 10%;
  }

  .about-us h2 {
    font-size: 1.75rem;
  }

  .about-us p {
    display: block;
    text-align: justify !important;
    text-align-last: center;
  }

  .counter {
    background-color: white;
  }

  .item-0 {
    font-size: 2.5rem;
  }

  .item-1 {


    font-size: 2rem;

  }

  .item-2 {
    font-size: 1.15rem;
  }

}
</style>

<script>

import Vue3AutoCounter from 'vue3-autocounter';
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';




// import required modules
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide,
    'vue3-autocounter': Vue3AutoCounter
  },
  setup() {
    return {
      modules: [EffectFade, Autoplay, Pagination, Navigation],
    };
  },
  methods: {
    myFunction() {
      var dots = document.getElementById("dots");
      var moreText = document.getElementById("more");
      var btnText = document.getElementById("myBtn");

      if (dots.style.display === "none") {
        dots.style.display = "inline";
        btnText.innerHTML = "Read more";
        moreText.style.display = "none";
      } else {
        dots.style.display = "none";
        btnText.innerHTML = "Read less";
        moreText.style.display = "inline";
      }
    }
  },
};

</script>