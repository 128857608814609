<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>Under Construction Projects</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Under Construction Projects</li>
                </ol>
            </div>
            <!-- end container -->
        </header>

        <section class="facilities">
            <div class="container">
                <div class="row">
                    
                    <div class="col-md-6">
                        <figure>
                            <img src="../assets/images/saburi.png" style="max-height: 310px;"/>
                            <figcaption class="mt-5">
                                <h3 class="text-center">SABURI C.H.S NX</h3>
                                <h5 class="text-center">SAI SECTION, AMBARNATH</h5>
                            </figcaption>
                        </figure>
                    </div>
                    <div class="col-md-6">
                        <figure>
                            <img src="../assets/images/shivhari.png " style="max-height: 310px; margin: 0 25% !important; "/>
                            <figcaption class="mt-5">
                                <h3 class="text-center">SHIV HARI NX</h3>
                                <h5 class="text-center">SHIVGANGA NAGAR, AMBARNATH</h5>
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

</script>

<style>

</style>