<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>Mahalaxmi Ashish-NX</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item" aria-current="page"><a href="#">VPM Developers</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Mahalaxmi Ashish-NX</li>
                </ol>
            </div>
            <!-- end container -->
        </header>
        <div class="gap"></div>
        <div class="container">
            <div class=" row">
                <div class="col-md-7">
                    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="../assets/images/vpm1.jpg" class="d-block w-100" alt="image">
                            </div>
                            <div class="carousel-item">
                                <img src="../assets/images/vpm2.jpg" class="d-block w-100" alt="image">
                            </div>
                            <div class="carousel-item">
                                <img src="../assets/images/vpm3.jpg" class="d-block w-100" alt="image">
                            </div>
                            <div class="carousel-item">
                                <img src="../assets/images/vpm4.jpg" class="d-block w-100" alt="image">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-5" style="font-size: 1.2rem;">
                    VPM Developers is a trusted brand partner of the esteemed Marathe Group, and our first project
                    together is the redevelopment of the old and dilapidated building called "Mahalaxmi Ashish." This
                    project, named Mahalaxmi Ashish NX, is part of the affordable housing scheme, and we at VPM
                    Developers are committed to providing our clients with a luxurious living experience.

                    We take pride in our unique Home Care initiative, which goes beyond the usual norms of affordable
                    housing.
                </div>

            </div>
            <div class="">

            </div>
            <!-- <h1 class="text-center plan" style="font-family: 'Playfair Display', serif;">PLAN</h1>
            <v-card>
                <v-tabs v-model="tab" align-tabs="center" color="deep-purple-accent-4">
                    <v-tab :value="1">1 BHK</v-tab>
                    <v-tab :value="2">2 BHK</v-tab>
                    <v-tab :value="3">Amenities</v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <v-window-item :value="1">
                        <v-container fluid>
                            <v-img :src="require('../assets/images/satyadeep1bhk.png')"></v-img>
                        </v-container>
                    </v-window-item>
                </v-window>
                <v-window v-model="tab">
                    <v-window-item :value="2">
                        <v-container fluid>
                            <v-img :src="require('../assets/images/satyadeep2bhk.png')"></v-img>
                        </v-container>
                    </v-window-item>
                </v-window>
                <v-window v-model="tab">
                    <v-window-item :value="3">
                        <v-container fluid>
                            <v-img :src="require('../assets/images/satyadeepamenities.jpg')"></v-img>
                        </v-container>
                    </v-window-item>
                </v-window>

            </v-card> -->

        </div>

        <div class="mt-8 text-center desktop">
            <h1 class="text-center plan mb-8" style="font-family: 'Playfair Display', serif;">LOCATION</h1>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3768.741189577816!2d73.22811!3d19.162803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ecd57223e661%3A0x1438e385116c8635!2sMahalaxmi%20Ashish%20Nx!5e0!3m2!1sen!2sin!4v1713514652360!5m2!1sen!2sin" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


        </div>

        <div class="mt-8 text-center mobile">
            <h1 class="text-center plan mb-8" style="font-family: 'Playfair Display', serif;">LOCATION</h1>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3768.741189577816!2d73.22811!3d19.162803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ecd57223e661%3A0x1438e385116c8635!2sMahalaxmi%20Ashish%20Nx!5e0!3m2!1sen!2sin!4v1713514652360!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="gap">

        </div>
    </div>
</template>

<style>
.plan {
    font-size: 3rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 5rem;
}
</style>

<script>
export default {
    data: () => ({
        tab: null,
    }),
}
</script>