import { createRouter , createWebHistory } from 'vue-router';
import LandingPage from '../src/components/LandingPage';
import ContactUs from '../src/components/ContactUs';
import AboutUs from './components/AboutUs.vue';
import ShreeShubhanand from './components/ShreeShubhanand.vue';
import GreenLand from './components/GreenLand.vue';
import SatyaDeep from './components/SatyaDeep.vue';
import MahalaxmiAshish from './components/MahalaxmiAshish.vue';
import OurServices from './components/OurServices.vue';
import UpcomingProjects from './components/UpcomingProjects.vue';
import UnderConstruction from './components/UnderConstruction.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'home', component: LandingPage},
        { path: '/contactus', name: 'contactus', component: ContactUs},
        { path: '/aboutus', name: 'aboutus', component: AboutUs},
        { path: '/shreeshubhanand', name: 'shreeshubhanand', component: ShreeShubhanand},
        { path: '/greenland', name: 'greenland', component: GreenLand},
        { path: '/satyadeep', name: 'satyadeep', component: SatyaDeep},
        { path: '/mahalaxmiashish', name: 'mahalaxmiashish', component: MahalaxmiAshish},
        { path: '/services', name: 'ourservices', component: OurServices},
        { path: '/upcomingprojects', name: 'upcomingprojects', component: UpcomingProjects},
        { path: '/underconstruction', name: 'underconstruction', component: UnderConstruction},
        { path: '/:notFound(.*)' , redirect: '/' },
    ],
    scrollBehavior(to) {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
});

export default router;