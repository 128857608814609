import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vue3Autocounter from 'vue3-autocounter';

 

// import store from './store';

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)

app.component('vue3-autocounter',Vue3Autocounter);
app.use(vuetify)
app.use(router);

// app.use(store);
app.mount('#app')
