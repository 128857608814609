<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>Our Services</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Servicest</li>
                </ol>
            </div>
            <!-- end container -->
        </header>

        <section class="facilities" data-aos="fade-up">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-door</v-icon>
                            <figcaption>
                                <h5>Doors</h5>
                                <p>A paneled main door with a digital (smart) lock and wooden doors creates a charming and secure entrance for your home. </p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-wall</v-icon>
                            <figcaption>
                                <h5>Walls</h5>
                                <p>Gypsum-finished internal walls create a sleek and customizable backdrop for your interior design vision.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-lightbulb</v-icon>
                            <figcaption>
                                <h5>Electrification</h5>
                                <p>Concealed copper wiring with adequate points for AC, Refrigirator, Geyser & Washing Machine, TV/cable, etc.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-window-open</v-icon>
                            <figcaption>
                                <h5>Windows</h5>
                                <p>A coated heavy section aluminium sliding on a granite sills with tinted glass.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-table-chair</v-icon>
                            <figcaption>
                                <h5>Kitchen</h5>
                                <p>Granite Kitchen platform with service Platform. Branded Stainless Steel Sink. Full height glazed tiles.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-lock</v-icon>
                            <figcaption>
                                <h5>Security</h5>
                                <p>Video door phone System for all flats with 24hrs round the clock security.Intercom facility for all flats.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-toilet</v-icon>
                            <figcaption>
                                <h5>Toilets</h5>
                                <p>Anti-skid tiles in flooring, Aluminium louvers, CP fitting from plumber. Hot/cold water diverter.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-shower</v-icon>
                            <figcaption>
                                <h5>Bathrooms</h5>
                                <p>Anti-skid tiles in flooring, Aluminium louvers, CP fitting from plumber. Hot/cold water diverter.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                    <div class="col-lg-4 col-md-6">
                        <figure>
                            <v-icon>mdi-floor-plan</v-icon>
                            <figcaption>
                                <h5>Flooring</h5>
                                <p>Vitrified flooring in Livingroom, Bedroom & Kitchen. Anti-skid wooden finish filing in all balconies.</p>
                            </figcaption>
                        </figure>
                    </div>
                    <!-- end col-4 -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </section>


    </div>



</template>

<style></style>

<script>

</script>