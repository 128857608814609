<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>Contact Us</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact</li>
                </ol>
            </div>
            <!-- end container -->
        </header>
        <section class="contact">
            <div class="container">
                <div class="row align-items-center">

                    <!-- end col-6 -->
                    <div class="col-lg-6 col-md-6 wow fadeInUp">
                        <address>
                            <strong>Visit Us</strong>
                            <p>Office No. 08, Plot No. 47,<br>
                                Nirankar, Opp. HDFC Bank,<br>
                                Hutatma Chowk, Ambarnath East.
                                421 501.</p>
                        </address>
                    </div>
                    <!-- end col-3 -->
                    <div class="col-lg-3 col-md-6 wow fadeInUp">
                        <address>
                            <strong>Contact Us</strong>
                            <p><a href="tel:8080146766" style="color: inherit;">+91 8080146766</a><br>
                                <a href="mailto:contact@gurunetwork.in"
                                    style="color: inherit;">care@marathegroup.com</a>
                            </p>
                        </address>
                    </div>
                    <!-- end col-3 -->
                </div>
                <!-- end row -->
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="map">
                            <div class="pattern-bg" data-stellar-ratio="1.03"></div>
                            <!-- end pattern-bg -->
                            <div class="holder" data-stellar-ratio="1.07">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.6946376142896!2d73.1809791758084!3d19.20853524779733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be793c1d2bd893d%3A0xa38f2a5a761fccd7!2sMarathe%20Group!5e0!3m2!1sen!2sin!4v1713780943053!5m2!1sen!2sin"
                                    width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <!-- end holder -->
                        </div>
                        <!-- end map -->
                    </div>
                    <!-- end col-6 -->
                    <div class="col-lg-6">
                        <div class="contact-form">
                            <form id="contact" name="contact" method="post" ref="form" @submit.prevent="sendEmail">
                                <div class="form-group">
                                    <span>Your name</span>
                                    <input type="text" name="name" id="name" autocomplete="off" required
                                        v-model="form.name">

                                </div>
                                <!-- end form-group -->
                                <div class="form-group">
                                    <span>Your e-mail</span>
                                    <input type="text" name="email" id="email" autocomplete="off" required
                                        v-model="form.email">

                                </div>
                                <!-- end form-group -->
                                <div class="form-group">
                                    <span>Subject</span>
                                    <input type="text" name="subject" id="subject" autocomplete="off" required
                                        v-model="form.subject">

                                </div>
                                <!-- end form-group -->
                                <div class="form-group">
                                    <span>Your message</span>
                                    <textarea name="message" id="message" autocomplete="off" required
                                        v-model="form.message"></textarea>

                                </div>
                                <!-- end form-group -->
                                <div class="form-group">
                                    <button id="submit" type="submit" name="submit">
                                        <v-progress-circular v-if="loading" indeterminate color="white" size="20"
                                            class="mr-3"></v-progress-circular>
                                        Submit
                                    </button>
                                    <button id="submit" type="submit" @click="resetForm" class="ml-8">Reset</button>

                                </div>
                                <!-- end form-group -->
                            </form>
                            <!-- end form -->
                            <div class="form-group">
                                <div id="success" class="alert alert-success wow fadeInUp" role="alert">
                                    
                                    Your message
                                    was sent successfully! We will be in touch as soon as we can.
                                </div>
                                <!-- end success -->
                                <div id="error" class="alert alert-danger wow fadeInUp" role="alert"> Something went
                                    wrong, try refreshing and submitting the form again. </div>
                                <!-- end error -->
                            </div>
                            <!-- end form-group -->
                        </div>
                        <!-- end contact-form -->
                    </div>
                    <!-- end col-6 -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </section>
        <!-- end contact -->
        <v-dialog v-model="successDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">Success</v-card-title>
                <v-card-text>Your message was sent successfully! We will be in touch as soon as we can.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="successDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="errorDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">Error</v-card-title>
                <v-card-text>Something went wrong, try refreshing and submitting the form again.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="errorDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    data() {
        return {
            form: {
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            successDialog: false,
            errorDialog: false,
            loading: false
        };
    },
    computed: {
        isValidEmail() {
            const atSymbol = this.form.email.indexOf('@');
            const dot = this.form.email.lastIndexOf('.');
            return atSymbol > 0 && dot > atSymbol + 1 && dot < this.form.email.length - 1;
        }
    },
    methods: {
        sendEmail() {
            if (!this.isValidEmail) {
                this.errorDialog = true;
                return;
            }
            this.loading = true;
            emailjs
                .sendForm('service_p6agdmd', 'template_1g2d8ys', this.$refs.form, 'GP1zwsP-o0wAbXK3d')
                .then(
                    () => {
                        this.successDialog = true;
                        this.resetForm();
                        this.loading = false;

                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                        this.errorDialog = true;
                        this.loading = false;
                    }
                );
        },
        resetForm() {
            this.form.name = '';
            this.form.email = '';
            this.form.subject = '';
            this.form.message = '';
        }
    }
};
</script>

<style></style>