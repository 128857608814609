<template>
    <div>
        <header class="page-header" data-background="images/slide01.jpg" data-stellar-background-ratio="1.15">
            <div class="container">
                <h1>Satyadeep-NX</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/"><a href="#">Start</a></router-link></li>
                    <li class="breadcrumb-item" aria-current="page"><a href="#">Impero Buildcon</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Satyadeep-NX</li>
                </ol>
            </div>
            <!-- end container -->
        </header>
        <div class="gap"></div>
        <div class="container">
            <div class=" row">
                <div class="col-md-7">
                    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img src="../assets/images/satyadeep1.jpg" class="d-block w-100" alt="image">
                            </div>
                            <div class="carousel-item">
                                <img src="../assets/images/satyadeep2.png" class="d-block w-100" alt="image">
                            </div>
                            <div class="carousel-item">
                                <img src="../assets/images/satyadeep3.png" class="d-block w-100" alt="image">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-5" style="font-size: 1.2rem;">
                    Welcome to Impero Buildcon, a premier real estate development company that brings together the
                    expertise of two esteemed developers - Marathe Enterprises and Awas Developers. Our joint venture
                    aims to deliver the finest properties in town that reflect our commitment to quality, innovation,
                    and sustainability.

                    At Impero Buildcon, we understand that home is more than just an investment - it is a dream come
                    true. That's why we strive to build properties that not only meet the needs of our customers but
                    exceed their expectations. Our focus is on creating homes that offer a perfect balance of comfort,
                    convenience, and luxury, backed by world-class amenities and impeccable craftsmanship.
                </div>

            </div>
            <div class="">

            </div>
            <h1 class="text-center plan" style="font-family: 'Playfair Display', serif;">PLAN</h1>
            <v-card>
                <v-tabs v-model="tab" align-tabs="center" color="#bd9a68">
                    <v-tab :value="1">1 BHK</v-tab>
                    <v-tab :value="2">2 BHK</v-tab>
                    <v-tab :value="3">Amenities</v-tab>
                </v-tabs>
                <v-window v-model="tab">
                    <v-window-item :value="1">
                        <v-container fluid>
                            <v-img :src="require('../assets/images/satyadeep1bhk.png')"></v-img>
                        </v-container>
                    </v-window-item>
                </v-window>
                <v-window v-model="tab">
                    <v-window-item :value="2">
                        <v-container fluid>
                            <v-img :src="require('../assets/images/satyadeep2bhk.png')"></v-img>
                        </v-container>
                    </v-window-item>
                </v-window>
                <v-window v-model="tab">
                    <v-window-item :value="3">
                        <v-container fluid>
                            <v-img :src="require('../assets/images/satyadeepamenities.jpg')"></v-img>
                        </v-container>
                    </v-window-item>
                </v-window>
                
            </v-card>

        </div>

        <div class="mt-8 text-center desktop">
            <h1 class="text-center plan mb-8" style="font-family: 'Playfair Display', serif;">LOCATION</h1>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3767.844293606431!2d73.187092!3d19.202002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7937eb8671921%3A0x864928116e608c4a!2sSatyadeep%20Society%20(sagar%20Apt.)!5e0!3m2!1sen!2sin!4v1713513851116!5m2!1sen!2sin" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


        </div>

        <div class="mt-8 text-center mobile">
            <h1 class="text-center plan mb-8" style="font-family: 'Playfair Display', serif;">LOCATION</h1>

            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3767.844293606431!2d73.187092!3d19.202002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7937eb8671921%3A0x864928116e608c4a!2sSatyadeep%20Society%20(sagar%20Apt.)!5e0!3m2!1sen!2sin!4v1713513851116!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="gap">

        </div>
    </div>
</template>

<style>
.plan {
    font-size: 3rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-top: 5rem;
}
</style>

<script>
export default {
    data: () => ({
        tab: null,
    }),
}
</script>